<template>
  <el-form
    :inline="true"
    :model="data"
    style="width: 96%; margin-left: 2%;margin-top: 10px;"
    v-loading.fullscreen.lock="fullscreenLoading"
    element-loading-text="正在火速加载数据..."
  >
    <el-form-item label="指标月份">
      <el-date-picker value-format="YYYY-MM" v-model="searchFilter.targetMonth" type="month" clearable />
    </el-form-item>
    <el-form-item label="门店区域">
      <el-cascader
        ref="casRef"
        v-model="searchFilter.regionCodeList"
        :options="areaLists"
        clearable
        filterable
        collapse-tags
        placeholder="请选择门店区域"
        collapse-tags-tooltip
        @change="changeArea()"
        :show-all-levels="false"
        :props="areaProps"
      ></el-cascader>
    </el-form-item>
    <el-form-item label="门店">
      <el-select-v2
        v-model="searchFilter.storeCodeList"
        :options="storeLists"
        filterable
        placeholder="请选择门店"
        class="search-select userSelect"
        multiple
        collapse-tags
        style="width: 300px;"
        collapse-tags-tooltip
      />
    </el-form-item>

    <el-form-item label="开业时间">
      <el-date-picker
        value-format="YYYY-MM"
        v-model="searchFilter.openingDate"
        type="monthrange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :clearable="true"
      />
    </el-form-item>

    <el-form-item>
      <el-button @click="queryLists" type="primary" v-if="authMenus.query">
        查询
      </el-button>
      <el-button @click="reseatFilter" v-if="authMenus.query">
        重置
      </el-button>

      <el-button style="padding:0px" v-if="authMenus.import">
        <el-upload action="#" :on-change="uploadFile" :show-file-list="false" :auto-upload="false">
          <el-button>导入月指标</el-button>
        </el-upload>
      </el-button>

      <el-button @click="reCalculate" v-if="authMenus.recalculate">
        重新计算
      </el-button>
      <el-button @click="exportData" v-if="authMenus.export">
        导出
      </el-button>
    </el-form-item>
  </el-form>
  <p style="color: red;margin-left: 2%;margin-bottom: 10px;">
    每天9:00自动更新业绩数据
  </p>
  <el-table
    :data="tableData"
    style="width: 96%; margin-left: 2%;"
    :emptyText="emptyTableText"
    ref="tableRef"
    @sort-change="handleSortChange"
  >
    <el-table-column align="center" width="160" prop="targetMonth" label="指标月份"> </el-table-column>
    <el-table-column
      align="center"
      width="200"
      sortable="custom"
      prop="monthlyRevenueRanking"
      label="月营收完成率排名"
    />

    <el-table-column align="center" width="160" prop="storeCode" label="门店编号"> </el-table-column>
    <el-table-column align="center" width="160" prop="storeName" label="门店名称"> </el-table-column>
    <el-table-column align="center" width="160" prop="organizationName" label="所属大区"> </el-table-column>
    <el-table-column align="center" width="160" prop="storeStats" label="门店属性"> </el-table-column>
    <el-table-column align="center" width="160" sortable="custom" prop="openingDate" label="开业时间">
    </el-table-column>
    <el-table-column
      align="center"
      width="200"
      sortable="custom"
      prop="monthlyRevenueIndicator"
      label="月营收指标(万元)"
    >
    </el-table-column>
    <el-table-column align="center" width="200" sortable="custom" prop="settleAmount" label="月营收(结算口径)">
    </el-table-column>
    <el-table-column align="center" width="200" sortable="custom" prop="monthlyRevenue" label="月营收(消费口径)">
    </el-table-column>
    <el-table-column align="center" width="200" sortable="custom" prop="dayMonthlyRevenueRanking" label="月营收排名">
    </el-table-column>
    <el-table-column align="center" width="200" sortable="custom" prop="completionRate" label="月营收完成率">
    </el-table-column>
    <el-table-column
      align="center"
      width="220"
      sortable="custom"
      prop="monthlyStoreValueProportionIndex"
      label="当月储值占比指标"
    >
    </el-table-column>
    <el-table-column align="center" width="200" sortable="custom" prop="currentStoredValue" label="当月储值(万元)">
    </el-table-column>
    <el-table-column
      align="center"
      width="200"
      sortable="custom"
      prop="monthlyCurrentStoredValueRanking"
      label="当月储值排名"
    />
    <el-table-column
      align="center"
      width="200"
      sortable="custom"
      prop="currentStoredValueProportion"
      label="当月储值占比"
    />
    <el-table-column
      align="center"
      width="220"
      sortable="custom"
      prop="currentStoredValueRatio"
      label="月储值占比完成率"
    >
    </el-table-column>
    <el-table-column
      align="center"
      width="220"
      sortable="custom"
      prop="currentStoredValueRanking"
      label="月储值占比完成率排名"
    />
    <el-table-column align="center" width="220" prop="createTime" label="更新时间" />
    <el-table-column align="center" label="操作" width="300" fixed="right">
      <template v-slot="{ row }">
        <el-button type="text" @click="updateTarget(row)" v-if="row.targetMonth != '合计'">编辑指标</el-button>
        <el-button type="text" @click="deleteTarget(row)" v-if="row.targetMonth != '合计'">删除指标</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="searchFilter.pageNum"
    v-model:page-size="searchFilter.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="searchFilter.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <el-dialog title="编辑月指标" v-model="updateDialogVisible" width="40%">
    <el-form :model="updateMonth" label-width="80px" class="dialog-form" :rules="updateRuleForm" ref="ruleFormRef">
      <el-form-item label="指标月份">
        {{ updateMonth.targetMonth }}
      </el-form-item>
      <el-form-item label="门店">
        {{ updateMonth.storeName }}
      </el-form-item>
      <el-form-item label="营收指标" prop="performanceIndicator" class="is-required">
        <el-input v-model="updateMonth.performanceIndicator">
          <template #append>万元</template>
        </el-input>
      </el-form-item>
      <el-form-item label="储值占比指标" prop="proportionStoredValue" class="is-required">
        <el-input v-model="updateMonth.proportionStoredValue">
          <template #append>%</template>
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="updateDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleSure(ruleFormRef)">确 定</el-button>
    </span>
  </el-dialog>

  <el-dialog title="删除月指标" v-model="deleteDialogVisible" width="40%">
    <el-form :model="deleteMonth" :rules="cancelRules" ref="cancelform">
      <el-form-item
        :label="
          `即将删除${deleteMonth.targetMonth.split('-').join('年')}月-${deleteMonth.storeCode}-${
            deleteMonth.storeName
          }的月指标`
        "
        label-width="1000"
      ></el-form-item>
      <el-form-item label="删除原因" class="is-required" prop="isDeleteRemark">
        <el-input
          placeholder="请输入删除原因"
          v-model="deleteMonth.isDeleteRemark"
          type="textarea"
          :rows="4"
          maxlength="200"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="deleteDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handlDeltSure(cancelform)">确 定</el-button>
    </span>
  </el-dialog>

  <el-dialog v-model="recancleDilalogVisible" width="40%" center>
    <el-form :model="searchFilter" :rules="reCalculateRules" ref="reCalculateRef">
      <el-form-item label="请确认重新计算数据的月份？"></el-form-item>
      <el-form-item label="指标月份" prop="time">
        <el-date-picker value-format="YYYY-MM" v-model="searchFilter.time" type="month" clearable />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="recancleDilalogVisible = false">取 消</el-button>
      <el-button type="primary" @click="handlRecancleSure(reCalculateRef)">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script setup>
import moment from 'moment'
import { onMounted, reactive, ref, nextTick } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import API from '../../service/index.js'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
import clearSelect from '@/lin/util/clearSelect2.js'
import _ from 'lodash'
import { post } from '@/lin/plugin/axios'
import { createMoveBall } from '@/utils/createMoveBall.js'

const casRef = ref()
const changeArea = () => {
  searchFilter.storeCodeList = []
  searchFilter.areaCode = searchFilter.regionCodeList.map(item => item[item.length - 1])
  nextTick(() => {
    clearSelect('userSelect')
    getShops()
  })
}
//搜索条件
const searchFilter = reactive({
  regionCodeList: [],
  storeCodeList: [],
  areaCode: [],
  pageNum: 1,
  pageSize: 10,
  total: 0,
  startTime: '',
  endTime: '',
  targetMonth: moment(new Date()).format('YYYY-MM'),
  time: moment(new Date()).format('YYYY-MM'),
  createTime: '',
  sortDirection: '',
  sortField: '',
})
const tableRef = ref(null)
//重制搜索条件
const reseatFilter = () => {
  searchFilter.storeCodeList = []
  searchFilter.regionCodeList = []
  searchFilter.areaCode = []
  searchFilter.targetMonth = moment(new Date()).format('YYYY-MM')
  searchFilter.pageNum = 1
  searchFilter.pageSize = 10
  searchFilter.startTime = ''
  searchFilter.endTime = ''
  searchFilter.time = ''
  searchFilter.createTime = ''
  searchFilter.sortDirection = ''
  searchFilter.sortField = ''
  tableRef.value.clearSort()
  resetDate()
  nextTick(() => {
    clearSelect('userSelect')
    getShops()
  })
  queryLists()
}

//重置时间
const resetDate = () => {
  searchFilter.openingDate = ['', '']
}

const updateDialogVisible = ref(false) //修改弹出框
const deleteDialogVisible = ref(false) //删除弹出框
const recancleDilalogVisible = ref(false) //删除弹出框
const updateMonth = ref({}) //需要操作的某一行数据
const deleteMonth = ref({}) //删除操作的某一行数据
const areaLists = ref([]) //区域
const storeLists = ref([]) //门店

//下拉列表属性
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'child',
  checkStrictly: true,
  multiple: true,
})

/**
 * 编辑指标的页面关闭页面
 */
const handleUpClose = target => {
  updateDialogVisible.value = false
}

/**
 * 删除指标的页面关闭页面
 */
const handleDelteClose = target => {
  deleteDialogVisible.value = false
}
const validatorMonthlyRevenueIndicator = (rule, value, callback) => {
  if (!/^\d+(\.\d{1,2})?$/.test(value)) {
    return callback(new Error('营收指标必须是小数或者整数'))
  }
  callback()
}
const validatorMonthlyStoreValueProportionIndex = (rule, value, callback) => {
  if (!/^\d+(\.\d{1,2})?$/.test(value)) {
    return callback(new Error('储值占比指标必须是小数或者整数'))
  }
  callback()
}
const ruleFormRef = ref(null)
const updateRuleForm = reactive({
  performanceIndicator: [{ validator: validatorMonthlyRevenueIndicator, trigger: 'change' }],
  proportionStoredValue: [
    {
      validator: validatorMonthlyStoreValueProportionIndex,
      trigger: 'change',
    },
  ],
})

/**
 * 编辑指标的确定
 */
const handleSure = _.throttle(
  async elform => {
    if (!elform) return
    await elform.validate(async (valid, fields) => {
      if (valid) {
        let res = await API.updateStoreMonthlyIndicator(updateMonth.value)
        if (res.code === '200') {
          ElMessage.success('更新成功')
          queryLists()
          updateDialogVisible.value = false
        } else {
          ElMessage.error(res.msg)
        }
      } else {
        ElMessage.info('请填写完成必要信息')
      }
    })
  },
  2000,
  {
    leading: true,
    trailing: false,
  },
)

const validateIsDeleteRemark = (rule, value, callback) => {
  if (!value?.trim()) {
    return callback(new Error('删除原因必填'))
  }
  callback()
}

const cancelform = ref(null)
const cancelRules = reactive({
  isDeleteRemark: [{ validator: validateIsDeleteRemark, trigger: 'change' }],
})

/**
 * 删除指标的确定
 */

const handlDeltSure = _.throttle(
  async elform => {
    if (!elform) return
    await elform.validate(async (valid, fields) => {
      if (valid) {
        const user = JSON.parse(localStorage.getItem('vuex'))

        let username = `${user.user.userInfo.userSaasUserVO.userName}`
        let param = {
          ...deleteMonth.value,
          isDeleteRemark: deleteMonth.value.isDeleteRemark.trim(),
          updateUser: username,
        }
        let res = await API.deleteStoreMonthlyIndicator(param)
        if (res.code === '200') {
          ElMessage.success('删除成功')
          queryLists()
          deleteDialogVisible.value = false
        } else {
          ElMessage.error(res.msg)
        }
      } else {
        ElMessage.info('请填写完成必要信息')
      }
    })
  },
  2000,
  {
    leading: true,
    trailing: false,
  },
)

const handleRecancleClose = async () => {
  recancleDilalogVisible.value = false
}

const reCalculateRef = ref(null)
const reCalculateRules = reactive({
  time: [{ required: true, message: '请输入指标月份', trigger: 'change' }],
})

const handlRecancleSure = async elform => {
  if (!elform) return
  await elform.validate(async (valid, fields) => {
    if (valid) {
      let res = await API.recalculate({
        targetMonth: searchFilter.targetMonth,
      })
      if (res.code === '200') {
        ElMessage.success('重新计算成功')
        queryLists()
        recancleDilalogVisible.value = false
      } else {
        ElMessage.error(msg)
      }
    } else {
      ElMessage.info('请填写完成必要信息')
    }
  })
}

const handleSortChange = ({ column, prop, order }) => {
  searchFilter.sortDirection = order === 'ascending' ? 'asc' : 'desc'
  searchFilter.sortField = prop
  queryLists()
}

const fullscreenLoading = ref(false)

const emptyTableText = ref('请先设置查询条件，再点击查询数据')
/**
 * 获取表单数据
 */
const tableData = ref([])
const queryLists = async () => {
  let param = {
    startTime: searchFilter.openingDate ? searchFilter.openingDate[0] : '',
    endTime: searchFilter.openingDate ? searchFilter.openingDate[1] : '',
    targetMonth: searchFilter.targetMonth,
    stores: searchFilter.storeCodeList,
    regions: searchFilter.areaCode,
    pageNum: searchFilter.pageNum,
    pageSize: searchFilter.pageSize,
    sortDirection: searchFilter.sortDirection,
    sortField: searchFilter.sortField,
  }
  if (authMenus.value.query) {
    let params = generateParam(param)
    //占坑
    tableData.value = [{ name: '合计' }]

    getTableData(params, 'STORE_REVENUE_MONTH_REPORT')

    if (params.pageNum > 1) {
      return tableData.value.splice(0, 1, totalRowData.value)
    }
    getTotalRowRequest(params, 'STORE_REVENUE_MONTH_REPORT')
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}

/**
 * @description: 生成请求参数
 * @param {*} type
 * @return {*}
 */
const generateParam = data => {
  let params = {
    pageNum: data.pageNum,
    pageSize: data.pageSize,
  }
  let model = _.cloneDeep(data)
  delete model.pageSize
  delete model.pageNum
  params.criteria = JSON.stringify(model)
  return params
}
const totalRowData = ref({})

// 获取报表数据
const getTableData = async (params, type) => {
  fullscreenLoading.value = true
  let res = await post(`/shadow/report/details?type=${type}`, params).finally(() => {
    fullscreenLoading.value = false
  })
  if (res.code == 200) {
    tableData.value.push(...(res.data.items ?? []))
    searchFilter.total = res.data.total
    emptyTableText.value = tableData.value.length == 0 ? '暂无数据' : ''
  } else {
    tableData.value = []
    searchFilter.total = 0
  }
}
/**
 * @description: 获取合计请求数据
 * @return {*}
 */
const getTotalRowRequest = async (params, type) => {
  let res = await post(`/shadow/report/summary?type=${type}`, params)
  if (res.code == 200) {
    totalRowData.value = res.data ?? {}
    tableData.value.splice(0, 1, totalRowData.value)
  } else {
    tableData.value.splice(0, 1)
  }
}

const authMenus = ref({})
const route = useRoute()
onMounted(async () => {
  operationAuth(route, authMenus.value)
  // queryLists()
  getAreas()
  getShops()
})

//获取区域数据
const getAreas = () => {
  API.getCheckArea().then(res => {
    areaLists.value = res.data
  })
}
const handleClose = target => {
  target = false
}

const getShops = () => {
  API.getStoreListByName({ list: searchFilter.areaCode }).then(res => {
    if (res.code === '200') {
      storeLists.value = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

/**
 * 重新计算
 */
const reCalculate = async () => {
  recancleDilalogVisible.value = true
  searchFilter.time = moment(new Date()).format('YYYY-MM')
}

//上传文件
const uploadFile = file => {
  API.inputBatchStoreMonthlyIndicator({ file: file.raw }).then(res => {
    if (res.code === '200') {
      ElMessage({
        message: '上传成功',
        type: 'success',
      })
      queryLists()
    } else {
      ElMessage.error(res.msg)
    }
  })
}

/**
 * 导出
 */
const exportData = async event => {
  let model = {}
  model.startTime = searchFilter.openingDate ? searchFilter.openingDate[0] : ''
  model.endTime = searchFilter.openingDate ? searchFilter.openingDate[1] : ''
  model.storeCode = searchFilter.storeCode
  model.storeName = searchFilter.storeName
  model.regions = searchFilter.areaCode
  model.stores = searchFilter.storeCodeList
  model.targetMonth = searchFilter.targetMonth
  fullscreenLoading.value = true
  createMoveBall(event)
  const param = generateParam(model)
  let res = await post(`/shadow/export/download?type=STORE_REVENUE_MONTH_REPORT`, param).finally(() => {
    fullscreenLoading.value = false
  })
  if (res.code == 200) {
    ElMessage.success('导出成功')
  } else {
    ElMessage.error('导出失败')
  }
}

/**
 * 修改某一行数据
 * @param {object} row
 */
const updateTarget = async row => {
  if (row.targetMonth == '合计') return
  let res = await API.queryStoreMonthlyIndicator({ targetMonth: row.targetMonth, storeCode: row.storeCode })
  if (res.code === '200') {
    updateMonth.value = res.data
  }
  updateDialogVisible.value = true
}

/**
 * 删除某一行数据
 * @param {object} row
 */
const deleteTarget = row => {
  row.isDeleteRemark = ''
  deleteDialogVisible.value = true
  deleteMonth.value = {
    targetMonth: row.targetMonth,
    storeCode: row.storeCode,
    storeName: row.storeName,
  }
}

//分页
const handleSizeChange = val => {
  searchFilter.pageSize = val
  queryLists()
}

//默认页
const handleCurrentChange = val => {
  searchFilter.pageNum = val
  queryLists()
}
</script>

<style scoped lang="scss">
.el-pagination {
  text-align: right;
  margin-right: 2%;
}
.dialog-form {
  ::v-deep .el-form-item__label {
    width: 150px !important;
  }
}

.delete_dialog-form {
  ::v-deep .el-form-item__label {
    width: 250px !important;
  }
}
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
.first-form {
  ::v-deep .el-form-item {
    margin-bottom: 0px !important;

    .el-form-item__content {
      margin-bottom: 0px !important;
    }
  }
}

.el-row {
  margin-bottom: 10px;
}
.dialog-footer {
  text-align: right;
  display: block;
}
</style>
